export default (theme) => {
	const warning = theme.color ? theme.color.default.warning : '#e68945';
	const success = theme.color ? theme.color.default.success : '#4cbac0';
	const danger = theme.color ? theme.color.default.error : '#de5b68';
	const primary = theme.color ? theme.palette.primary.main : '#4cbac0';
	const secondary = theme.color ? theme.palette.secondary.main : '#cccccc';
	const textPrimary = theme.color ? theme.palette.text.primary : '#414751';
	const primaryLight = theme.palette.primary.light;

	return {
		icon: {
			color: theme.palette.text.secondary,
			fontSize: 22,
			margin: '0 6px',

			'& svg': {
				width: 22,
				height: 22
			}
		},

		isHover: {
			color: primary,
			cursor: 'pointer',

			'&:focus, &:hover, &:visited': {
				color: primary,
				opacity: 0.8
			}
		},

		iconColor: {
			'&inherit': {
				'&, &:focus, &:hover, &:visited': {
					color: 'inherit'
				}
			},

			'&textPrimary': {
				'&, &:focus, &:hover, &:visited': {
					color: textPrimary
				}
			},

			'&primary': {
				'&, &:focus, &:hover, &:visited': {
					color: primary
				}
			},

			'&primaryLight': {
				'&, &:focus, &:hover, &:visited': {
					color: primaryLight
				}
			},

			'&secondary': {
				'&, &:focus, &:hover, &:visited': {
					color: secondary
				}
			},

			// default
			'&white': {
				'&, &:focus, &:hover, &:visited': {
					color: '#fff'
				}
			},

			// default
			'&black': {
				'&, &:focus, &:hover, &:visited': {
					color: theme.palette.text.primary
				}
			},

			'&success': {
				'&, &:focus, &:hover, &:visited': {
					color: success
				}
			},

			'&danger': {
				'&, &:focus, &:hover, &:visited': {
					color: danger
				}
			},

			'&warning': {
				'&, &:focus, &:hover, &:visited': {
					color: warning
				}
			}
		},

		iconSize: {
			'&xs': {
				fontSize: 18,

				'& svg': {
					width: 20,
					height: 20
				}
			},

			'&sm': {
				fontSize: 20,

				'& svg': {
					width: 22,
					height: 22
				}
			},

			'&md': {
				fontSize: 40,

				'& svg': {
					width: 40,
					height: 40
				},

				'& .material-icons': {
					fontSize: 40
				}
			},

			'&lg': {
				fontSize: 52,

				'& svg': {
					width: 54,
					height: 54
				}
			},

			'&xl': {
				fontSize: 66,

				'& svg': {
					width: 68,
					height: 68
				}
			},

			'&xxl': {
				// it is only for svg
				width: '100%',

				'& svg': {
					width: '80%',
					height: 120,

					[theme.breakpoints.up('lg')]: {
						width: '40%'
					}
				}
			},

			'&hero': {
				// it is only for svg
				width: '100%',

				'& svg': {
					width: '80%',
					height: 200,
					margin: '10px auto',

					[theme.breakpoints.up('lg')]: {
						width: '40%',
						height: 300,
						margin: '20px auto'
					}
				}
			},

			'&auto': {
				'&, & svg': {
					width: 'auto',
					height: 'auto'
				}
			}
		},

		marginRatio: {
			'&sm': {
				margin: '0 2px'
			},

			'&lg': {
				margin: '0 10px'
			},

			'&xl': {
				margin: '0 12px'
			},

			'&only-right': {
				margin: '0 8px 0 0'
			},

			'&only-left': {
				margin: '0 0 0 8px'
			},

			'&none': {
				margin: 0
			}
		}
	};
};
