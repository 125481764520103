import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import MaterialTooltip from '@material-ui/core/Tooltip';

import tooltipStyles from '../assets/styles/tooltip';

class Tooltip extends React.Component {
	render() {
		const {
			classes,
			content,
			placement,
			wrapperclassname,
			children,
			color,
			padding,
			PopperProps,
			open,
			id,
			enterDelay,
			arrow,
			...rest
		} = this.props;

		const tooltipClasses = classNames({
			[classes.tooltip]: true,
			[classes[color]]: color,
			[classes.padding + padding]: padding
		});

		return (
			<MaterialTooltip
				id={id}
				title={content || ''}
				classes={{
					popper: classes.popper,
					tooltip: tooltipClasses,
					arrow: 'tooltip-arrow'
				}}
				placement={placement}
				disableHoverListener={content ? false : true}
				disableFocusListener={true}
				// disableTouchListener={true}
				PopperProps={PopperProps}
				enterDelay={enterDelay}
				arrow={arrow}
				open={open}
			>
				<div title='' data-tooltip={content} {...rest} className={wrapperclassname}>
					{children}
				</div>
			</MaterialTooltip>
		);
	}
}

Tooltip.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.node,
	wrapperclassname: PropTypes.string,
	id: PropTypes.string,
	color: PropTypes.oneOf(['white', false]),
	padding: PropTypes.oneOf(['lg', false]),
	content: PropTypes.any,
	style: PropTypes.any,
	arrow: PropTypes.bool,
	enterDelay: PropTypes.number,
	display: PropTypes.string
};

export default withStyles(tooltipStyles)(Tooltip);
