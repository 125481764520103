import {ratio, marginRatio, paddingRatio, previewPaperWidth} from '@zegal/components/src/components/src/assets/global.style';

export default (theme) => {
	const header = theme.ratio ? theme.ratio.size.header : '56px';
	const headerLargeScreen = theme.ratio ? theme.ratio.size.headerLargeScreen : '64px';

	return {
		wrapper: {
			// don't delete
		},

		// margin ratio
		marginRatio: {
			...marginRatio(theme)
		},

		paddingRatio: {
			...paddingRatio(theme)
		},

		// inner
		wrapperInner: {
			'&normal, &normal-page-preview-html, &normal-page-preview-editor, &normal-compress-equal-to-editor, &normal-compress-equal-to-editor-v2': {
				width: '100%',
				height: 'auto',
				paddingTop: '0 !important'
			},

			'&normal-page-preview-editor': {
				height: '100%'
			},

			'&flat': {
				width: '100%',
				paddingRight: 16,
				paddingLeft: 16
			},

			'&compress': {
				width: '100%',
				paddingRight: 16,
				paddingLeft: 16,
				paddingBottom: '1.3rem',

				[theme.breakpoints.up('md')]: {
					width: '60%',
					margin: '0 auto'
				}
			},

			'&compress1': {
				width: '100%',
				paddingRight: 16,
				paddingLeft: 16,
				boxSizing: 'border-box',

				[theme.breakpoints.up('md')]: {
					maxWidth: 1280 / 2,
					margin: '0 auto'
				}
			},

			'&compress2': {
				width: '100%',
				paddingRight: 16,
				paddingLeft: 16,
				boxSizing: 'border-box',

				[theme.breakpoints.up('md')]: {
					maxWidth: theme.breakpoints.width('md'),
					margin: '0 auto',
					paddingRight: 24,
					paddingLeft: 24
				}
			},

			'&compress3': {
				width: '100%',

				[theme.breakpoints.up('md')]: {
					width: '40%',
					margin: '0 auto'
				}
			},

			// preview page layout which have width exactly to a4 size paper
			'&page-preview-html': {
				maxWidth: previewPaperWidth,
				margin: '0 auto',
				paddingBottom: 16,

				[theme.breakpoints.up('md')]: {
					paddingRight: 'unset',
					paddingLeft: 'unset',
					width: previewPaperWidth
				}
			},

			'&compress-equal-to-editor': {
				[theme.breakpoints.up('md')]: {
					width: 'calc(100% - 20px)',
					maxWidth: 816,
					margin: '0 auto'
				}
			},

			// preview page layout which is made for vendor editors
			'&page-preview-editor': {
				paddingTop: '0 !important',
				overflow: 'hidden',

				'& .compress-wrapper': {
					padding: '16px 8px 6px',

					'&.is-editor': {
						width: '100%'
					},

					'& > *:last-child': {
						marginBottom: 0
					},

					'&:empty': {
						display: 'none'
					},

					[theme.breakpoints.up('md')]: {
						width: '100%',
						maxWidth: 816,
						margin: '0 auto',
						paddingLeft: 18
					}
				}
			},

			// use for spacing between two items
			'&items-spacing': {
				'& > *': {
					marginRight: '12px !important'
				},

				'& > *:last-child': {
					marginRight: '0 !important'
				}
			}
		},

		// display
		wrapperDisplay: {
			'&contents': {
				display: 'contents'
			},

			'&flex': {
				display: 'flex'
			},

			'&inline-flex': {
				display: 'inline-flex'
			},

			'&block': {
				display: 'block',
				width: '100%'
			},

			'&cover': {
				display: 'block',
				width: '100%',
				height: '100%'
			},

			// for positioning wrapper above everything
			'&mask': {
				position: 'fixed',
				top: 0,
				bottom: 0,
				right: 0,
				left: 0,
				zIndex: 1399
			},

			'&fullWidth': {
				width: '100%'
			},

			'&fullHeight': {
				height: '100%'
			},

			'&fullPage': {
				height: `calc(100vh - ${header})`,

				[theme.breakpoints.up('md')]: {
					height: `calc(100vh - ${headerLargeScreen})`
				}
			},

			'&header, &header-sm-only, &header-lg-only': {
				height: ratio.size.header,
				minHeight: ratio.size.header,
				marginBottom: 0,
				padding: 0,
				border: 0,
				borderRadius: 0,
				zIndex: theme.zIndex.appBar,

				'&-lg-only': {
					height: ratio.size.headerLargeScreen
				},

				'&-xs-only': {
					height: 36
				},

				[theme.breakpoints.up('sm')]: {
					height: ratio.size.headerLargeScreen,

					'&-sm-only': {
						height: ratio.size.header
					}
				}
			}
		},

		// under
		wrapperUnder: {
			'&header': {
				paddingTop: 16,

				[theme.breakpoints.up('md')]: {
					paddingTop: 24
				}
			},

			// wrapper under one fixed header
			'&header1': {
				paddingTop: header,

				[theme.breakpoints.up('sm')]: {
					paddingTop: headerLargeScreen
				},

				// only if disply='header' and under='header1' is user at a same time
				'&$wrapperDisplay': {
					'&header, &header-sm-only, &header-lg-only': {
						padding: 0,
						top: header,

						[theme.breakpoints.up('sm')]: {
							top: headerLargeScreen
						},
					}
				}
			},

			// wrapper under two fixed header
			'&header2': {
				paddingTop: 'calc(' + header + ' * 2)',

				[theme.breakpoints.up('sm')]: {
					paddingTop: 'calc(' + headerLargeScreen + ' * 2)'
				}
			},

			// wrapper under one fixed header
			'&fixedHeader1': {
				top: header,
				zIndex: theme.zIndex.appBar - 1,

				[theme.breakpoints.up('sm')]: {
					top: headerLargeScreen
				}
			},
		},

		// position
		wrapperPosition: {
			'&vertical-center': {
				position: 'absolute',
				top: '50%',
				transform: 'translateY(-50%)'
			},

			'&horizontal-center': {
				position: 'absolute',
				left: '50%',
				transform: 'translateX(-50%)'
			},

			'&total-center': {
				position: 'absolute',
				top: '40%',
				left: '50%',
				transform: 'translate(-50%, -40%)'
			},

			'&relative': {
				position: 'relative'
			},

			'&fixed': {
				position: 'fixed'
			},

			'&sticky': {
				position: 'sticky',
				zIndex: theme.zIndex.appBar
			},

			'&absolute': {
				position: 'absolute',

				'&$wrapperDisplay': {
					'&mask': {
						zIndex: theme.zIndex.appBar
					}
				}
			},

			'&fixed-up': {
				position: 'fixed',
				top: 0
			},

			'&fixed-bottom': {
				position: 'fixed',
				bottom: 0
			},

			'&total-center-md-up': {
				[theme.breakpoints.up('md')]: {
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)'
				}
			}
		},

		// flex
		wrapperFlexAuto: {
			'&one': {
				flex: '1 auto'
			},

			'&two': {
				flex: '2'
			}
		},

		// flex wrap
		wrapperFlexWrap: {
			'&wrap': {
				flexWrap: 'wrap'
			},

			'&no-wrap': {
				flexWrap: 'no-wrap'
			}
		},

		// align items
		wrapperFlexAlignItems: {
			'&flex-start': {
				display: 'flex',
				alignItems: 'flex-start'
			},

			'&center': {
				display: 'flex',
				alignItems: 'center'
			},

			'&baseline': {
				display: 'flex',
				alignItems: 'baseline'
			},

			'&flex-end': {
				display: 'flex',
				alignItems: 'flex-end'
			},

			'&stretch': {
				display: 'flex',
				alignItems: 'stretch'
			}
		},

		// align self
		wrapperFlexAlignSelf: {
			'&flex-start': {
				alignSelf: 'flex-start'
			},

			'&center': {
				alignSelf: 'center'
			},

			'&baseline': {
				alignSelf: 'baseline'
			},

			'&flex-end': {
				alignSelf: 'flex-end'
			}
		},

		// justify
		wrapperFlexjustifyContent: {
			'&space-between': {
				display: 'flex',
				justifyContent: 'space-between'
			},

			'&space-around': {
				display: 'flex',
				justifyContent: 'space-around'
			},

			'&flex-start': {
				display: 'flex',
				justifyContent: 'flex-start'
			},

			'&flex-end': {
				display: 'flex',
				justifyContent: 'flex-end'
			},

			'&center': {
				display: 'flex',
				justifyContent: 'center'
			},

			'&inherit': {
				display: 'flex',
				justifyContent: 'inherit'
			}
		},

		// direction
		wrapperDirection: {
			'&row': {
				display: 'flex',
				flexDirection: 'row'
			},

			'&row-md-up': {
				display: 'flex',
				flexDirection: 'column',

				// removing margin of last children in small screen column mode
				[theme.breakpoints.only('xs')]: {
					'& > *:last-child': {
						marginBottom: 0
					}
				},

				[theme.breakpoints.up('md')]: {
					flexDirection: 'row'
				}
			},

			'&column': {
				display: 'flex',
				flexDirection: 'column'
			},

			'&column-md-up': {
				display: 'flex',
				flexDirection: 'row',

				[theme.breakpoints.up('md')]: {
					flexDirection: 'column'
				}
			}
		},

		wrapperFloat: {
			'&right': {
				float: 'right'
			},

			'&right-md-up': {
				[theme.breakpoints.up('md')]: {
					float: 'right'
				}
			},

			'&left': {
				float: 'left'
			},

			'&left-md-up': {
				[theme.breakpoints.up('md')]: {
					float: 'left'
				}
			}
		},

		// overflow
		wrapperOverflow: {
			'&auto': {
				overflow: 'auto'
			},

			'&hidden': {
				overflow: 'hidden'
			}
		},

		wrapperCustomScrollbar: {
			// custom cubic scrollbar
			'&::-webkit-scrollbar': {
				width: '.4rem',
				height: '.4rem'
			},

			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#cccccc',

				'&:hover': {
					backgroundColor: '#cccccc',
				}
			}
		},

		wrapperDisableMouseEvents: {
			pointerEvents: 'none',
			touchAction: 'none'
		},

		wrapperDisableSelection: {
			userSelect: 'none'
		},

		wrapperEnablePointer: {
			cursor: 'pointer'
		}
	};
};
