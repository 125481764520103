import React from 'react';
import {withRouter} from 'react-router-dom';

import {ModalBody} from '@zegal/components/src/components/src/notification';
import {MailConfirmed} from '@zegal/components/src/components/src/icons';
import Wrapper from '@zegal/components/src/components/src/wrappers/wrapper';
import {lsGet} from '@zegal/components/src/base/src/common/utils/localstorage';
import {cookieGet, cookieAxe, cookieSet} from '@zegal/components/src/base/src/common/utils/cookie';

const EmailConfirmedModal = withRouter(
	class EmailConfirmedModal extends React.Component {
		componentDidMount = () => {
			const {app} = this.props;

			this.unlisten = this.props.history.listen((location) => {
				const url = cookieGet('nextUrl', false, true) || lsGet('nextUrl');
				const alreadyConfirm = cookieGet('alreadyConfirm', false, true);
				if (url === location.pathname && app.stores.general.isAuthenticated) {
					app.actions.showModal({
						Component: Modal,
						showCloseButton: true,
						description1: alreadyConfirm
							? app.t('auth.register.email.alreadyconfirm.description1')
							: app.t('auth.register.email.confirm.description1')
					});
					cookieAxe(app)('nextUrl');
					cookieSet(app)('alreadyConfirm', true);
					this.unlisten();
				}
			});
		};

		render() {
			return null;
		}
	}
);

const Modal = ({description1}) => {
	const _props = {
		title: 'Email Confirmed',
		Icon: MailConfirmed,
		description1
	};

	return (
		<Wrapper under='header'>
			<ModalBody {..._props} />
		</Wrapper>
	);
};

export default EmailConfirmedModal;
