import {headerBoxShadow} from '@zegal/components/src/components/src/assets/global.style';
export default (theme) => ({
	appBar: {
		flexDirection: 'row',
		...headerBoxShadow(theme)
	},

	logo: {
		color: theme.color.dl_mediumgrey,
		cursor: 'pointer',

		'& svg': {
			display: 'flex',
			width: 'calc(48px * 2)',
			height: 48,

			[theme.breakpoints.up('md')]: {
				width: 'calc(58px * 2)',
				height: 58
			}
		}
	},

	toolBar: {
		maxWidth: 1240,
		width: '100%',
		margin: '0 auto',
		display: 'flex',
		alignItems: 'normal',
		flexDirection: 'column',
		justifyContent: 'space-between',
		color: theme.color.dl_grey,
		padding: '0 5px',

		[theme.breakpoints.up('sm')]: {
			padding: '0 20px'
		},

		[theme.breakpoints.up('md')]: {
			alignItems: 'center',
			flexDirection: 'row'
		},

		[theme.breakpoints.up('lg')]: {
			padding: 0
		}
	},

	toolBarMenu: {
		display: 'none',
		width: '100%',
		paddingBottom: 4,

		[theme.breakpoints.up('md')]: {
			width: 'auto',
			display: 'flex',
			paddingBottom: 0
		}
	}
});
