import {get, set, isFunction} from 'lodash';
import {
	action,
	decorate
	// computed,
	// observable,
} from 'mobx';

class Store {
	toggle(key) {
		if (this[key] !== undefined) {
			this[key] = !this[key];
			return;
		}

		// if the key is a complex string, eg: key.key2
		const value = get(this, key);

		set(this, key, !value);
	}

	// create this store
	static create(callback) {
		// console.log('initialState', initialState)

		const store = new this();

		isFunction(callback) && callback(store);

		// need a model:
		// store.state = array.map(item => TodoModel.fromJS(store, item))

		return store;
	}
}

decorate(Store, {
	toggle: action
});

export default Store;
