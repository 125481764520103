import React, {CSSProperties} from 'react';
import isString from 'lodash/isString';
// nodejs library for concatinating classes
import classNames from 'classnames';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import MaterialPaper from '@material-ui/core/Paper';

import Button from '../buttons/button';
import paperStyle from '../assets/styles/paper/base';
import {IWrapperProps} from '../wrappers/wrapperTypes';
import {ISpacingWrapperProps} from '../lab/wrapper';

/*
 * Usage example
 *
 * <Paper
 *	color='primary | secondary | danger | warning | transparent | white'
 *	padding='xs | sm | lg | none'
 *	collapsed='bool'
 *	margin='sm | none'
 *	border= 'dashed' | bool		(gives paper border)
 *	opacity='bool'				(gives paper opacty color)
 *	focus='bool'				(gives strong box shadow)
 *	slat='bool'					(gives border bottom to its child element)
 *	panel='bool'				(gives panel layout)
 *	cover='bool'				(gives fullheigt and fullWidth layout)
 *	className='string'
 *	onClose='function'
 * >
 * 	'node | string'
 * </Paper>
 *
 */

interface IPaperProps extends IWrapperProps, ISpacingWrapperProps {
	classes: any;
	color?:
		| 'primary'
		| 'secondary'
		| 'danger'
		| 'disabled'
		| 'warning'
		| 'transparent'
		| 'white'
		| 'inherit-nav'
		| 'bodyColor'
		| false;
	padding?: 'xs' | 'sm' | 'lg' | 'xl' | 'none' | false;
	border?: 'dashed' | boolean;
	opacity?: boolean;
	display?: 'fullHeight' | 'table-md-up';
	focus?: 'one' | 'two' | 'three' | boolean;
	slat?: boolean;
	panel?: boolean;
	cover?: boolean;
	reset?: 'radius' | boolean;
	onClose?: () => void;
	style?: CSSProperties;
	className?: string;
	id?: string;
	component?: React.ReactNode;
	children?: React.ReactNode;
	value?: string;
}

function Paper(props: IPaperProps) {
	const {
		children,
		value,
		classes,
		onClose,
		color,
		margin,
		padding,
		border,
		display,
		opacity,
		focus,
		slat,
		panel,
		cover,
		reset,
		className,
		...rest
	} = props;

	let paperClasses = classNames({
		[classes.paper]: true,
		[classes.marginRatio + margin]: margin,
		[classes.paddingRatio + padding]: padding,
		[`${classes.paperBorder}${isString(border) ? border : ''}`]: border,
		[classes.paperDisplay + display]: display,
		[classes.paperOpacity]: opacity,
		[`${classes.paperFocus}${isString(focus) ? focus : ''}`]: focus,
		[classes.paperSlat]: slat,
		[classes.paperPanel]: panel,
		[classes.paperCover]: cover,
		[`${classes.paperReset}${isString(reset) ? reset : ''}`]: reset,
		[className]: className
	});

	if (color && typeof color === 'string') {
		paperClasses += ` ${classes[color]}`;
	}

	const paperProps = {
		...rest,
		className: paperClasses
	};

	return (
		<MaterialPaper {...paperProps}>
			{onClose && (
				<Button
					onClick={onClose}
					color={color}
					justIcon
					size={
						padding === 'none' ? false : padding
					} /*Clear button size is same as the padding ratio of paper*/
					simple
					disableRipple
					className={classes.buttonClose}
				>
					clear
				</Button>
			)}

			{children ? children : value}
		</MaterialPaper>
	);
}

export default withStyles(paperStyle)(Paper);
