import React from 'react';
import Popper from './popper';

class RegularPopper extends React.Component {
	state = {
		open: this.props.open || false
	};

	handleClick = (e) => {
		e.stopPropagation();

		this.setState(({open}) => ({
			open: !open
		}));
	};

	handleClose = () => {
		this.setState({
			open: false
		});
	};

	onClickAway = () => {
		if (this.state.open) {
			this.handleClose();
		} else {
			return false;
		}
	};

	render() {
		const {target, content, ...rest} = this.props;
		const {open} = this.state;

		return (
			<Popper
				{...rest}
				open={open}
				target={() => target(this.handleClick, open)}
				content={() => content(this.handleClose)}
				onClickAway={this.onClickAway}
			/>
		);
	}
}

export default RegularPopper;
