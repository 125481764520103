import {beautifulDomId} from '@zegal/components/src/base/src/common/lodashMixins';

export default ({logEvent, name = 'click_event', title, button, onClick}) => {
	const newHandler = (event) => {
		if (!onClick) {
			return event;
		}

		const data = {};

		if (title) {
			data.title = beautifulDomId(title);
		}

		if (button) {
			data.button = beautifulDomId(button);
		}

		logEvent &&
			logEvent(name, {
				data
			});

		onClick(event);
	};

	return newHandler;
};
