import React from 'react';
import {Route, Redirect} from 'react-router-dom';

export default (App) => {
	// wrap <Route> or <PrivateRoute>, then when
	// sub routes are added to any route it'll work
	App.RouteBuilder = (route) => {
		App.log('Adding route:', route, 'Core', 3);

		return (
			<Route
				path={route.path}
				{...(route.options || {})}
				render={(props) => {
					if (!route.public && !App.stores.general.isAuthenticated) {
						!App.stores.general.isAuthenticated && App.actions.captureUrl();
						return (
							<Redirect
								to={{
									pathname: '/login',
									state: {from: props.location}
								}}
							/>
						);
					}

					// if we have an associated model, pass that too
					if (route.model) {
						props.match.model = route.model;
					}

					props.match.options = route.options || {};

					// some routes need the app injected into the component (eg: from common components)
					if (route.app) {
						props.App = App;
					}

					return (
						// pass the sub-routes down to keep nesting
						<route.component {...props} routes={route.routes} />
					);
				}}
			/>
		);
	};

	App.setNextRoute = function(route, options) {
		// console.log('Setting next route:', route, options);
		App._nextRoute = route;
		App._nextRouteOptions = options;
	};

	App.setNextUrl = function(url) {
		App.nextUrl = url;
	};

	/**
	 * Get the next route.
	 *
	 * Used after login etc.
	 *
	 * @param {boolean} reset - Reset the next route once returned
	 *
	 * @return {string} next route
	 */
	App.getNextRoute = function(reset) {
		// set a default
		let result = App.getConfig('homepath');

		if (App.nextUrl) {
			result = App.nextUrl;
			delete App.nextUrl;
			App.log('Returning next route:', result, 'Base App Core', 3);
			return result;
		}

		// if one has been put in, use that instead:
		if (App._nextRoute) {
			result = [App._nextRoute, App._nextRouteOptions];

			if (reset) {
				delete App._nextRoute;
				delete App._nextRouteOptions;
			}
		}

		App.log('Returning next route:', result, 'Base App Core', 3);
		return result;
	};

	App.getCurrentRoute = function() {
		return window.location.pathname;
	};

	App.fireNextRoute = function() {
		// make sure we send in 'true' to clear the next route once we have it
		let [nextRoute, nextRouteOptions] = this.getNextRoute(true);

		App.log('Loading next route:', nextRoute, 'Base App Core', 3);

		if (typeof nextRoute === 'function') {
			nextRoute(nextRouteOptions);
		} else {
			this.radio.request(nextRoute, nextRouteOptions);
		}
	};

	App.parseFromURL = (name, url, allowPlus = App.getConfig('allowPlusInQueryString')) => {
		if (!url) {
			url = window.location.href;
		}
		name = name.replace(/[[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) {
			return null;
		}
		if (!results[2]) {
			return '';
		}
		if (allowPlus) {
			return decodeURIComponent(results[2]);
		}
		return decodeURIComponent(results[2].replace(/\+/g, ''));
	};

	return App;
};
