import React from 'react';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';

import wrapperStyle from '../assets/styles/wrapper/base';
import {IWrapperProps} from './wrapperTypes';

function Wrapper(props: IWrapperProps) {
	const {
		id,
		scribeKey,
		under,
		margin,
		padding,
		inner,
		display,
		float,
		position,
		flex,
		flexWrap,
		alignItems,
		alignSelf,
		justify,
		direction,
		overflow,
		customScrollbar,
		disableMouseEvents,
		disableSelection,
		enablePointer,
		className,
		children,
		classes,
		component,
		onClick,
		onDoubleClick,
		onContextMenu,
		style
	} = props;

	const WrapperComponent = component || 'div';

	const wrapperClassName = classNames({
		[classes.wrapper]: true,
		[classes.wrapperUnder + under]: under, // wrapper for content under fixed header
		[classes.marginRatio + margin]: margin,
		[classes.paddingRatio + padding]: padding,
		[classes.wrapperInner + inner]: inner, // inner wrapper for content
		[classes.wrapperDisplay + display]: display,
		[classes.wrapperFloat + float]: float,
		[classes.wrapperPosition + position]: position,
		[classes.wrapperFlexAuto + flex]: flex,
		[classes.wrapperFlexWrap + flexWrap]: flexWrap,
		[classes.wrapperFlexAlignItems + alignItems]: alignItems,
		[classes.wrapperFlexAlignSelf + alignSelf]: alignSelf,
		[classes.wrapperFlexjustifyContent + justify]: justify,
		[classes.wrapperDirection + direction]: direction,
		[classes.wrapperOverflow + overflow]: overflow,
		[classes.wrapperCustomScrollbar]: customScrollbar,
		[classes.wrapperDisableMouseEvents]: disableMouseEvents,
		[classes.wrapperDisableSelection]: disableSelection,
		[classes.wrapperEnablePointer]: enablePointer,
		[className]: className
	});

	const wrapperProps = {
		id,
		style,
		onClick,
		onDoubleClick,
		onContextMenu,
		'data-testid': id,
		'data-scribe-key': scribeKey,
		'data-zegal-component': props['data-zegal-component'],
		className: wrapperClassName
	};

	return <WrapperComponent {...wrapperProps}>{children}</WrapperComponent>;
}

export default withStyles(wrapperStyle)(Wrapper);
