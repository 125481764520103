export default (theme) => ({
	wrapper: {
		marginTop: 'calc(56px + 6px)',
		overflowX: 'hidden',

		[theme.breakpoints.up('sm')]: {
			marginTop: 64
		}
	},

	wrapperOnNoTopbar: {
		height: '100vh'
	},

	wrapperContent: {
		width: '100%',
		padding: '0px 10px',
		margin: '0 auto',

		[theme.breakpoints.up('sm')]: {
			padding: '20px 10px'
		},

		[theme.breakpoints.up('md')]: {
			minWidth: 1024,
			maxWidth: 1024
		}
	},

	wrapperContentOnNoTopbar: {
		width: '100%',
		height: '100%'
	}
});
