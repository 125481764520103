import get from 'lodash/get';
import isObject from 'lodash/isObject';
import {types, getSnapshot, getEnv} from 'mobx-state-tree';
import {utc} from '@zegal/components/src/base/src/common/date';

import generalActions from '@zegal/components/src/base/src/common/stores/_general';

export const getOS = () => {
	var OSName = 'Unknown OS';
	if (navigator.userAgent.indexOf('Win') !== -1) OSName = 'Windows';
	if (navigator.userAgent.indexOf('Mac') !== -1) OSName = 'Macintosh';
	if (navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux';
	if (navigator.userAgent.indexOf('Android') !== -1) OSName = 'Android';
	if (navigator.userAgent.indexOf('like Mac') !== -1) OSName = 'iOS';
	// console.log('Your OS: ' + OSName);
	return OSName;
};

const getBrowserInfo = () => {
	var nAgt = navigator.userAgent;
	var browserName = navigator.appName;
	var fullVersion = '' + parseFloat(navigator.appVersion);
	var majorVersion = parseInt(navigator.appVersion, 10);
	var nameOffset, verOffset, ix;

	// In Opera, the true version is after "Opera" or after "Version"
	if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
		browserName = 'Opera';
		fullVersion = nAgt.substring(verOffset + 6);
		if ((verOffset = nAgt.indexOf('Version')) !== -1) fullVersion = nAgt.substring(verOffset + 8);
	}
	// In MSIE, the true version is after "MSIE" in userAgent
	else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
		browserName = 'Microsoft Internet Explorer';
		fullVersion = nAgt.substring(verOffset + 5);
	}
	// In Chrome, the true version is after "Chrome"
	else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
		browserName = 'Chrome';
		fullVersion = nAgt.substring(verOffset + 7);
	}
	// In Safari, the true version is after "Safari" or after "Version"
	else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
		browserName = 'Safari';
		fullVersion = nAgt.substring(verOffset + 7);
		if ((verOffset = nAgt.indexOf('Version')) !== -1) fullVersion = nAgt.substring(verOffset + 8);
	}
	// In Firefox, the true version is after "Firefox"
	else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
		browserName = 'Firefox';
		fullVersion = nAgt.substring(verOffset + 8);
	}
	// In most other browsers, "name/version" is at the end of userAgent
	else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
		browserName = nAgt.substring(nameOffset, verOffset);
		fullVersion = nAgt.substring(verOffset + 1);
		if (browserName.toLowerCase() === browserName.toUpperCase()) {
			browserName = navigator.appName;
		}
	}
	// trim the fullVersion string at semicolon/space if present
	if ((ix = fullVersion.indexOf(';')) !== -1) fullVersion = fullVersion.substring(0, ix);
	if ((ix = fullVersion.indexOf(' ')) !== -1) fullVersion = fullVersion.substring(0, ix);

	majorVersion = parseInt('' + fullVersion, 10);
	if (isNaN(majorVersion)) {
		fullVersion = '' + parseFloat(navigator.appVersion);
		majorVersion = parseInt(navigator.appVersion, 10);
	}

	return {
		browserName,
		fullVersion,
		majorVersion,
		appName: navigator.appName,
		userAgent: navigator.userAgent
	};
};

const info = {
	os: getOS(),
	...getBrowserInfo()
};

const Log = types.model('Log', {
	name: '',
	data: types.frozen()
	// data: LogData,
});

// const FlatLogData = types.frozen()

export default types
	.model('LogStore', {
		data: types.optional(types.array(Log), [])
	})
	.actions(generalActions)

	.actions((self) => {
		const App = getEnv(self).app;

		return {
			clear() {
				self.data = [];
			},

			normalizeFlat(data = {}) {
				// console.warn('Normalize logger data', data)

				let finalData = {
					time: utc(),
					distinct_id: App.stores.user.email,
					user_id: App.stores.user._id,
					org_id: get(App.stores, 'orgs.current._id'),
					current_url: window.location.href,
					browser: info.browserName,
					browser_version: info.fullVersion,
					screen_height: window.screen.height,
					screen_width: window.screen.width,
					os: info.os,
					platform: App.getConfig('platform'),
					platform_version: App.getConfig('version'),
					ip: get(App, 'stores.user.location.ip'),
					ll: get(App, 'stores.user.location.geo.ll'),
					country_code: get(App, 'stores.user.location.geo.country'),
					country: get(App, 'stores.user.location.country'),
					// custom
					name: data.name || 'unnamed_event',
					data: {}
				};

				if (!isObject(data.data)) {
					finalData.data = {
						message: data.data
					};
				} else {
					Object.assign(finalData, data.data);
				}

				return finalData;
			},

			normalize(data = {}) {
				// console.log('Normalize data', data)

				if (!data.name) {
					data.name = 'unnamed_event';
					// throw Error(data)
				}

				if (!isObject(data.data)) {
					data.data = {
						message: data.data
					};
				}

				if (!data.data) {
					data.data = {};
				}

				data.data.time = utc();
				data.data.distinct_id = App.stores.user.email;
				data.data.user_id = App.stores.user._id;
				data.data.org_id = get(App.stores, 'orgs.current._id');
				data.data.current_url = window.location.href;
				data.data.browser = info.browserName;
				data.data.browser_version = info.fullVersion;
				data.data.screen_height = window.screen.height;
				data.data.screen_width = window.screen.width;
				data.data.os = info.os;
				data.data.platform = App.getConfig('platform');
				data.data.platform_version = App.getConfig('version');
				data.data.ip = get(App, 'stores.user.location.ip');
				data.data.ll = get(App, 'stores.user.location.geo.ll');
				data.data.country_code = get(App, 'stores.user.location.geo.country');
				// data.data.timezone = get(App, 'stores.user.location.geo.timezone')
				data.data.country = get(App, 'stores.user.location.country');

				return data;
			},

			send() {
				// clear the data right away, so we don't get multiple posts
				// if a few events fire at once
				const snapshot = getSnapshot(self);

				if (snapshot.data.length === 0) {
					return;
				}

				self.clear();

				// quiet: true will cause failures to not infiniate loop
				self.serverCall('/logevents', snapshot.data, 'POST', {
					maxAjaxRetries: 0,
					quiet: true,
					withCredentials: true
				}).catch((error) => {
					console.error('Error sending events:', error, {
						data: self.data
					});
				});
			},

			add(data) {
				try {
					const normalized = self.normalize(data);
					// console.log('Normalized', normalized);
					self.data.push(normalized);
				} catch (error) {
					// log here about bad log? ...lol
					console.warn('BAD LOG:', error);
					return false;
				}

				if (self.cacheIsFull) {
					self.send();
				}
			}
		};
	})

	.views((self) => {
		const App = getEnv(self).app;

		return {
			get cacheIsFull() {
				return self.data.length >= App.getConfig('logLimit');
			}
		};
	});
