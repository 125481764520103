const roleMap = [
	{
		role: 'DEFAULT', // Default permissions for un-authed user:
		name: 'Un-authed user',
		activities: [
			{
				module: 'auth',
				actions: {
					main: {
						googleAuth: false,
						normalAuth: true
					}
				}
			}
		],
		routes: [
			{
				module: 'auth',
				actions: {
					registration: {
						// fastRegistration: true,
						// registerInit: true
					}
					// registrationreact: {
					// 	registerInit: true
					// },
				}
			}
		]
	}
];

export default roleMap;
