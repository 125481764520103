/**
 * Module: Registration
 *
 * @module Registration
 * @namespace Registration
 */
import App from '@zegal/payments/src/app';

const Registration = [
	{
		path: '/register',
		component: App.loadFile({loader: () => import('./register')}),
		public: true,
		options: {exact: true}
	},
	{
		path: '/register/billing-admin/:email',
		component: App.loadFile({loader: () => import('./register')}),
		options: {exact: true},
		public: true
	},
	{
		path: '/register/:market/',
		component: App.loadFile({loader: () => import('./register')}),
		public: true,
		options: {exact: true}
	},
	{
		path: '/confirm-email/:email',
		component: App.loadFile({loader: () => import('./components/confirmEmail')}),
		public: true
	},
	{
		path: '/confirmation/:id/:token',
		component: App.loadFile({loader: () => import('./components/emailConfirmed')}),
		public: true
	},
	// need to refactor later
	{
		path: '/member-invitation/:invite_id/:email/:token',
		component: App.loadFile({loader: () => import('./components/billingInvitation')}),
		public: true
	},
	{
		path: '/member-invitation/:invite_id/:email',
		component: App.loadFile({loader: () => import('./components/billingInvitation')}),
		public: true
	}
];

export default Registration;
