/* eslint-disable flowtype/require-valid-file-annotation */
import React from 'react';
import {inject, observer} from 'mobx-react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';

import App from '@zegal/payments/src/app';
import TopBar from './topBar';
import styles from './wrapper.styles';

class PageWrapper extends React.Component {
	static propTypes = {
		classes: PropTypes.object.isRequired
	};

	state = {
		open: false,
		currentPath: ''
	};

	handleDrawerOpen = () => {
		this.setState({open: true});
	};

	handleDrawerClose = () => {
		this.setState({open: false});
	};

	// shouldComponentUpdate(nextProps, nextState) {
	// 	// console.log('nextProps', nextProps);
	// 	// console.log('htis.props', this.props);
	// 	// console.log('nextState', nextState);
	// 	// console.log('htis.state', this.state);

	// 	if (this.props.location.pathname !== nextProps.location.pathname) {
	// 		// console.log('pathname CHANGED, rendering wrapper');
	// 		return true
	// 	}
	// 	return false
	// }

	componentDidMount() {
		App.history = this.props.history;
	}

	render() {
		const {general, menu, children, history, classes} = this.props;
		const {open} = this.state;
		// console.log('this.props', this.props);
		// console.log('Rendering wrapper');

		return general.isAuthenticated ? (
			<React.Fragment>
				<TopBar open={open} history={history} />

				<main className={menu.appMenuBar ? classes.wrapper : classes.wrapperOnNoTopbar}>
					<div className={menu.appMenuBar ? classes.wrapperContent : classes.wrapperContentOnNoTopbar}>
						{children}
					</div>
				</main>
			</React.Fragment>
		) : (
			children
		);
	}
}

export default withStyles(styles)(inject('general', 'menu')(withRouter(observer(PageWrapper))));
