import moment, {MomentFormatSpecification} from 'moment';

/**
 * THIS UTIL IS TO ABSTRACT MOMENT
 *
 * So we can remove it easily later on
 *
 */

export const formatAMPM = 'h:mm A';
export const formatAMPMSmall = 'h:mm a';

export type TDate = moment.Moment | string;

/**
 * Returns a date format of the passed date string.
 * @example
 * // https://momentjs.com/docs/#/parsing/creation-data/
 * getFormatOfDate('2016-01-01') // -> 'YYYY-MM-DD'
 * @param {string} date
 * @returns {string} String representation of the format of the passed date string.
 */
export const getFormatOfDate = (date: TDate): MomentFormatSpecification => moment(date).creationData().format || '';

// default get ISO current date: 2019-01-29T10:42:14+05:45
export const getDate = (date?: TDate, format?: string) => {
	return moment(date).format(format);
};

// returns locale aware date and time
export const locale = (date?: TDate) => {
	return moment(date).format('LLL');
};

// returns `Yesterday at 11:55 AM`
export const calendar = (date: TDate) => {
	return moment(date).calendar();
};

export const utc = () => {
	return moment.utc().format();
};

// convert a date from a known format to another one
export const parseDate = (date?: TDate, format?: string, outputFormat?: string) => {
	return moment(date, format).format(outputFormat);
};

// add days to current time
export const add = (days: string, date: TDate, format?: string) => {
	const added = moment(date).add(days, 'days');

	if (!format) {
		return added.format();
	}

	// console.log('added.format()', added.format());
	// console.log('added.utc()', added.utc());
	// console.log('added.utc().format()', added.utc().format());

	return added.format(format);
};

// input: 2019-01-25T05:12:07.746Z
// returns => 12/12/2018 10:24 AM
export const display = (date: TDate) => {
	return getDate(date, 'DD/MM/Y ' + formatAMPM);
};

// returns => December 12th 2018
export const displaySimple = (date: TDate) => {
	return getDate(date, 'MMMM Do YYYY');
};

// returns => Dec 2018
export const displayMonthYear = (date: TDate) => {
	return getDate(date, 'MMM YYYY');
};

// returns => December 12th 2018, 10:25:24 am
export const displayLong = (date: TDate) => {
	return getDate(date, 'MMMM Do YYYY, h:mm:ss a');
};

// returns => 2018 11 04
export const sortableDate = (date: TDate) => {
	return getDate(date, 'YYYY MM DD');
};

// returns => Wed, Dec 12th 2018, 10:25:59 am
export const displayLongest = (date: TDate) => {
	return getDate(date, 'ddd, MMM Do YYYY, h:mm:ss a');
};

// returns => 12 Dec 2018, 10:26 am
export const displayLong2 = (date: TDate, format = getFormatOfDate(date)) => {
	return parseDate(
		date,
		// @ts-ignore
		format,
		'D MMM YYYY, hh:mm a'
	);
};

//
// NEW STYLE:
//

export const dateFormat1 = (date: TDate) => {
	return getDate(date, 'MMMM Do YYYY');
};

export const dateFormat2 = (date: TDate) => {
	return getDate(date, 'MMMM Do YYYY, h:mm:ss a');
};

// output: 1 February 2019
export const dateFormat3 = (date?: TDate, parse: boolean = false) => {
	const format = 'D MMMM YYYY';

	if (parse) {
		return parseDate(date, format, format);
	}

	return getDate(date, format);
};

export const dateFormat4 = (date: TDate) => {
	return getDate(date, 'D MMM YYYY');
};

//
// Date calculations
//

export const isAfter = (date: TDate, afterDate: TDate, format = '') => {
	return moment(date, format).isAfter(afterDate);
};

export const isBefore = (date: TDate, beforeDate: TDate, format = '') => {
	return moment(date, format).isBefore(beforeDate);
};

export const fromNow = (date: TDate) => {
	return moment(date).fromNow();
};

// Date calculation (only date)
export const dateIsBefore = (date: TDate, beforeDate: TDate, format = '') => {
	return moment(date, format).isBefore(beforeDate, 'date');
};
export const dateIsSame = (date: TDate, beforeDate: TDate, format = '') => {
	return moment(date, format).isSame(beforeDate, 'date');
};

// ==> "1 Jun 2020 23:50"
export const lockByFormat = (date) => {
	return moment(date).format('D MMM YYYY H:mm');
};

export const inDays = (date) => {
	return moment(date).diff(moment(), 'days');
};

//
// NOT RECOMMENDED:
//

export const getDateLibRaw = () => moment;
