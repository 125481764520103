import React, {useState, useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import Wrapper from '@zegal/components/src/components/src/wrappers/wrapper';

interface IWhiteLabelLogoProps {
	orgs?: any;
	general?: any;
	whiteLabelLogoSrc?: string;
}

export const WhiteLabelLogoRaw = (props: IWhiteLabelLogoProps) => {
	const [whiteLabelLogoSrc, setImageSrc] = useState(props.whiteLabelLogoSrc);

	useEffect(() => {
		const namespace = props.orgs.current.namespace;

		if (namespace && props.general) {
			setImageSrc(props.general.theme.logo);
		}
	}, [props.orgs, props.orgs.current.namespace, props.general]);

	return (
		<>
			<Wrapper className='topbar-logo-wrapper'>
				{whiteLabelLogoSrc && <img style={{height: '50px'}} src={whiteLabelLogoSrc} alt='logo' />}
			</Wrapper>
		</>
	);
};

export default inject('orgs', 'general')(observer(WhiteLabelLogoRaw));
