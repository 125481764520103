import {
	// has,
	reverse,
	isNaN,
	endsWith,
	trimEnd,
	sortBy,
	attempt,
	isString,
	isEmpty,
	isObject
} from 'lodash';
import moment from 'moment';

export const dateFormat = (date, format = 'MMMM Do YYYY') => {
	return moment(date).format(format);
};

export const getKeys = (keys) => keys.split('-') || [];

// filter out non number and convert result to number
export const giveNumbers = (string = '') => {
	string = string.toString ? string.toString() : string;

	let decimals = string.split('.');

	let isNegative = false;

	if (decimals[0][0] === '-') {
		isNegative = true;
	}

	let result = decimals[0].replace(/\D+/g, '');

	if (decimals.length > 1) {
		result = result + '.' + decimals[1];
	}

	if (isNegative) {
		result = result * -1;
	}

	return result ? parseFloat(result) : 0;
};

export const Utils = {
	beautifulDomId(string) {
		if (!string || !string.toLowerCase) {
			return '';
		}

		return string.toLowerCase().replace(/ /g, '-');
	},

	parseJSON(string) {
		return attempt(JSON.parse.bind(null, string));
	},

	stringToArray(dataString, separator = ',') {
		if (dataString && !isEmpty(dataString) && isString(dataString)) {
			return dataString.split(separator);
		}
		return [];
	},

	capitalize(string, lowerCaseRest = true) {
		return isString(string)
			? string.charAt(0).toUpperCase() + (lowerCaseRest ? string.substring(1).toLowerCase() : string.substring(1))
			: '';
	},

	dateFromSeconds(date) {
		return dateFormat(moment(date * 1000, 'x'));
	},

	dateFormat,

	dateFormatForDateBlock(date) {
		return dateFormat(date, 'D MMMM YYYY');
	},

	dateFormatLong(date) {
		return moment(date).format('MMMM Do YYYY, h:mm:ss a');
	},

	fromNow(date) {
		return moment(date).fromNow();
	},

	sortArray(data, field, newistFirst = true) {
		const sorted = sortBy(data, field);

		return newistFirst ? reverse(sorted) : sorted;
	},

	getKeys,
	getIdKey: (keys) => getKeys(keys)[1],
	giveNumbers,

	moment: (options) => moment(options),

	/**
	 * Number Format ()
	 *
	 * Taken from underscore.string, small mode adding parseFloat
	 *
	 * @param {string} number - number to work with
	 * @param {string} dec - decimal places
	 * @param {string} desp - decimal separator
	 * @param {string} tsep -
	 */
	numberFormat(number, decimalLimit, dsep, tsep) {
		if (parseInt(number, 10) === 0) {
			return '0';
		}

		if (isNaN(number) || number === null || number === '' || !number) {
			return '';
		}

		if (decimalLimit !== undefined) {
			number = parseFloat(number).toFixed(parseInt(decimalLimit, 10));
		} else {
			// convert to number, removing excess, then back to string, so we can split
			number = parseFloat(number) + '';
		}

		tsep = typeof tsep === 'string' ? tsep : ',';

		let parts = number.split('.');
		let fnums = parts[0];
		let decimals = parts[1] ? (dsep || '.') + parts[1] : '';

		return fnums.replace(/(\d)(?=(?:\d{3})+$)/g, '$1' + tsep) + decimals;
	},

	priceFormat(number) {
		// remove decimals
		return numberFormat(parseInt(number, 10), 0);
	},

	/**
	 * Format into money style
	 *
	 * 1 -> 1.0
	 * 1.0 -> 1.00
	 * 1,000 -> 1,000.00
	 */
	moneyFormat(value = 0) {
		let result = Utils.giveNumbers(value);

		if (isNaN(result)) {
			return '';
		}

		if (endsWith(result, '..') || (result.split && result.split('.').length >= 3)) {
			result = trimEnd(result, '.');
		}

		if (endsWith(value, '.')) {
			// console.log('Returned from 1');
			return value;
		}

		// console.log('Returned from 3');
		result = parseFloat(result.toFixed(2)).toLocaleString('en-US');

		// if it is 1000.00, parseFloat will remove the .00,
		// so we need to 1,000 -> 1,000.00
		let parts = result.split('.');
		if (parts.length === 1) {
			result += '.00';
		}

		// change: 1.0 -> 1.00
		if (parts.length === 2 && parts[1].length === 1) {
			result += '0';
		}

		return result;
	},

	/**
	 * Number format extreme
	 *
	 * more advanced:
	 * - min/max checking
	 * - only digits
	 * - only one decimal point
	 * - add commas
	 *
	 * @param {String|Number} number -
	 * @param {Object} limits -
	 * @param {Object} options -
	 *
	 */
	numberFormatExtreme(number, limits, options) {
		let result = {
			valid: true,
			number: undefined
		};

		let value = Utils.giveNumbers(number) + '';

		// console.log('Starting format for:', value);
		result.number = value;

		if ('' === number) {
			// console.log('Empty number found');
			// this.ui.input.parsley().reset()
			return result;
		}

		if (endsWith(value, '..')) {
			// console.log('Ends with: ..');
			value = trimEnd(value, '.');
			result.number = parseFloat(value).toLocaleString('en-US') + '.';
			// console.log('1 result.number', result.number);
			return result;
		}

		if (value.split('.').length >= 3) {
			// eg: was 1.1.1, now array of 3 entries
			value = trimEnd(value, '.');
			result.number = parseFloat(value).toLocaleString('en-US', {maximumSignificantDigits: 20});
			// console.log('2 result.number', result.number);
		}

		if (endsWith(value, '.')) {
			// console.log('Ends with: .');
			// console.log('3 result.number', result.number);
			return result;
		}

		if (endsWith(value, '0.')) {
			// console.log('4 result.number', result.number);
			return result;
		}

		if (value !== '') {
			// limit decimal places
			if (options && options.decimalLimit) {
				result.number = parseFloat(value).toLocaleString('en-US', {
					minimumFractionDigits: parseInt(options.decimalLimit, 10),
					maximumFractionDigits: parseInt(options.decimalLimit, 10)
				});
			} else {
				result.number = parseFloat(value).toLocaleString('en-US');
			}
		}

		result.raw = Utils.giveNumbers(result.number);

		if (limits) {
			// console.log('limits', limits, result.raw);
			if (limits.min !== undefined && limits.min !== '') {
				// console.log('limits.min', limits.min);
				if (result.raw < limits.min) {
					result.error = `Number is less than min (${result.raw} < ${limits.min})`;
					result.valid = false;
				}
			}

			if (limits.max !== undefined && limits.max !== '') {
				// console.log('limits.max', limits.max);
				if (result.raw > limits.max) {
					result.error = `Number is greater than max (${result.raw} > ${limits.max})`;
					result.valid = false;
				}
			}
		}

		// console.log('result', result);

		if (result.number === 'NaN') {
			// console.log('Nan found');
			result.error = 'Number is NaN';
			result.number = value;
			result.valid = false;
		}

		// we want a string back
		result.raw = result.raw + '';

		return result;
	},

	stripCommas(input) {
		return input.val().replace(/,/g, '');
	},

	/**
	 * Returns true if objects are completely equal
	 *
	 * @returns {boolean}
	 */
	areEqualObjects(object1, object2) {
		const keys1 = Object.keys(object1);
		const keys2 = Object.keys(object2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
			const val1 = object1[key];
			const val2 = object2[key];
			const areObjects = isObject(val1) && isObject(val2);

			if ((areObjects && !areEqualObjects(val1, val2)) || (!areObjects && val1 !== val2)) {
				return false;
			}
		}

		return true;
	}
};

/**
 * Generate accumulated result of a array value
 *
 * @returns {number} total sum value
 */
export const reduceSum = function (array, target) {
	const number = array.reduce((ac, cv, key) => {
		if (target) {
			return ac + giveNumbers(array[key][target]);
		} else {
			return ac + giveNumbers(cv);
		}
	}, 0);

	return number;
};

/**
 * Returns true if it is localhost
 *
 * @returns {boolean}
 */
export const isLocalhost = () => {
	if (
		window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
	) {
		return true;
	}

	return false;
};

export const parseJSON = Utils.parseJSON;
export const parseErrorMsg = Utils.parseErrorMsg;
export const stripCommas = Utils.stripCommas;
export const moneyFormat = Utils.moneyFormat;
export const priceFormat = Utils.priceFormat;
export const numberFormat = Utils.numberFormat;
export const numberFormatExtreme = Utils.numberFormatExtreme;
export const getIdKey = Utils.getIdKey;
export const sortArray = Utils.sortArray;
export const fromNow = Utils.fromNow;
export const dateFormatLong = Utils.dateFormatLong;
export const dateFormatForDateBlock = Utils.dateFormatForDateBlock;
export const dateFromSeconds = Utils.dateFromSeconds;
export const stringToArray = Utils.stringToArray;
export const capitalize = Utils.capitalize;
export const beautifulDomId = Utils.beautifulDomId;
export const areEqualObjects = Utils.areEqualObjects;

// export {...Utils}

export default Utils;
