import {fade} from '@material-ui/core/styles/colorManipulator';
import {giveGradient} from '../../global.style';

export default (theme) => ({
	backDrop: {
		backgroundColor: fade(theme.palette.background.default, 0.6)
	},

	dialog: {
		width: '100%',
		maxHeight: '100%',
		margin: 0,

		[theme.breakpoints.up('md')]: {
			margin: 48,
			maxHeight: `calc(100% - 96px)`
		}
	},

	staticHeight: {
		[theme.breakpoints.up('md')]: {
			minHeight: '60%'
		}
	},

	fullScreen: {
		borderRadius: 0
	},

	stickOnBottomXs: {
		[theme.breakpoints.down('xs')]: {
			position: 'fixed',
			bottom: 0,
			minHeight: '30vh',
			height: 'auto !important'
		}
	},

	dialogPaper: {
		borderRadius: 0,
		boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .20)',

		[theme.breakpoints.up('md')]: {
			margin: 12,
			height: 'auto',
			maxHeight: 'calc(100% - 12px)'
		},

		[theme.breakpoints.down('xs')]: {
			height: '100%'
		},

		'&$fullScreen': {
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			maxHeight: '100%',
			margin: 0
		}
	},

	showTopBorder: {
		'&::before': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			height: 4,
			background: giveGradient(theme)
		},
	},

	paperMd: {
		maxWidth: '100%',

		[theme.breakpoints.up('md')]: {
			maxWidth: 900,

			// custom cubic scrollbar
			'&::-webkit-scrollbar': {
				width: '.4rem',
				height: '.4rem'
			},

			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#cccccc',

				'&:hover': {
					backgroundColor: '#cccccc',
				}
			}
		}
	},

	// color

	secondary: {
		backgroundColor: theme.palette.background.default
	},

	dialogTitle: {
		fontSize: '1rem',
		fontWeight: 700,
		padding: '28px 24px 12px'
	},

	dialogContent: {
		overflow: 'unset',
		padding: '8px 24px 24px',

		'&.collapsed': {
			padding: 0
		},

		'&::-webkit-scrollbar': {
			width: 0
		},

		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'transparent'
		}
	},

	dialogCloseButton: {
		position: 'absolute',
		color: theme.palette.primary.main,
		right: 0,
		top: 5,
		margin: 6,
		zIndex: theme.zIndex.appBar,
		fontSize: 28,

		'&:hover': {
			color: theme.palette.primary.main
		}
	}

});
