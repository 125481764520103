import '@zegal/components/src/base/src/common/vendor/customPolyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import includes from 'lodash/includes';
import has from 'lodash/has';
import isBoolean from 'lodash/isBoolean';
import map from 'lodash/map';
import isObject from 'lodash/isObject';

import {Provider, inject, observer} from 'mobx-react';
import {withRouter, BrowserRouter as Router} from 'react-router-dom';

import App from '@zegal/payments/src/app';
import info from './info.json';
import config from './common/config/app';
import MenuWrapper from './modules/menu/wrapper';
import RegRoutes from './modules/registration/app';

// style stuff
import {WrapThemeProvider} from './common/components/wrapThemeProvider';
import EmailConfirmedModal from '@zegal/components/src/components/src/modals/confirmedEmail';

// import ZegalWebWorker from 'common/webWorker'
// import registerServiceWorker from './registerServiceWorker';
// import '@zegal/components/src/base/src/auth/app'
// import '@zegal/components/src/base/src/common/utils'
// console.log('Starting app:', info.version, App);

App.fetchI18N = (domain) => {
	if (domain) {
		return import('./common/scribe.domain.json');
	}

	return import('./common/scribe.json');
};

App.defaults = {
	version: info.version,

	// since we don't use orgs in this project, we need to disable them
	fakeOrg: true,
	seeDisclaimer: true,

	// make sync use our special headers for auth
	updateBackboneSync: true,

	// the route to goto after login
	homepath: '/',
	disableExtraCaching: true,

	platform: 'billing'
};

App.modalRegion = {};

App.adminModules = [];

App.UnAuthRoutes = [];
App.UnAuthRoutes = [...RegRoutes];

App.userModules = [
	import('./modules/layout/app.js'),
	import('./modules/home/app.js'),
	import('./modules/receipt/app.js'),
	import('./modules/subscription/app.js'),
	import('./modules/card/app.js'),
	import('./modules/org/app.js')
];

App.authModules = [
	// import('./modules/auth/app.js'),
];

App.processes.afterSetup = () => {
	App.cache.xhrCalls = [];
	// console.log('-----------SETUP COMPLETE');
	let title = App.getConfig('customTitle');
	if (title) {
		App.actions.changeTitle(title, {manual: true});
	}

	// set axios common header equal to api version for app
	// axios.defaults.headers.common['X-Dragon-Law-API-Version'] = '0.10.0'

	App.settings.APIDTEPrefix = '/payments/';
	App.settings.homepath = '/payments/home';

	if (App.getConfig('debug')) {
		window.app = App;
	}
};

App.processes.afterLogin = () => {
	try {
		const setup = async () => {
			// this fetches the org, and set's it as current on the store
			await App.stores.orgs.setCurrent(App.stores.user.primary_org_id);
			const {namespace} = App.stores.orgs.current;
			namespace &&
				App.actions
					.updateTheme(App.stores.orgs.current.namespace)
					.changeFavicon(namespace)
					.changeTitle(namespace, {manual: false});

			const integrations = await import('@zegal/components/src/integrations/src/app');

			if (!App.stores.general.theme.intercom) {
				if (isBoolean(App.getSetting('integrations').intercom?.enabled)) {
					App.getSetting('integrations').intercom.enabled = false;
				}
			}

			const intercomSettings = await import('./common/components/intercom');

			await integrations.start(
				{
					intercom: intercomSettings.default
				},
				App
			);

			// add in custom Zegal events.
			const integrationsModule = App.module('Integrations');
			if (integrationsModule && integrationsModule.MixPanel) {
				const mixPanelEvents = await import('./common/mixPanelEvents');
				// console.log('mixPanelEvents', mixPanelEvents)
				integrationsModule.MixPanel.addEvents((logEvent) => mixPanelEvents.default(logEvent));
			}

			if (integrationsModule && integrationsModule.GA) {
				const GAEvents = await import('./common/gaEvents');
				// console.log('GAEvents', GAEvents)
				integrationsModule.GA.addEvents((logEvent) => GAEvents.default(logEvent));
			}
		};

		setup();
	} catch (e) {
		App.log('app_error', {message: e.message, type: 'integeration-loading-error'});
	}

	App.event('user:landed');
};

App.processes.afterLogout = () => {
	// reset all stores
	App.actions.setupStores();
	App.module('Integrations') && App.module('Integrations').unload();
	App.setNextUrl(App.getConfig('homepath'));
	App.sockets.stopAll();
};

class AuthWrapperRaw extends React.Component {
	render() {
		const {general, children, history} = this.props;

		const pathname = window.location.pathname;
		// console.log('pathname', pathname);
		// console.log('App.stores.general.shouldLogout', general.shouldLogout);
		const shouldProceedWithLogout = general.shouldLogout && !includes(['/login', '/logout', '/'], pathname);
		// console.log('shouldProceedWithLogout', shouldProceedWithLogout);

		if (shouldProceedWithLogout) {
			history.push('/logout');
			return <div>Starting logout...</div>;
		}

		return children;
	}
}

const AuthWrapper = inject('general')(withRouter(observer(AuthWrapperRaw)));

class AppWrapper extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			authRoutes: false
		};
	}

	componentDidMount() {
		App.processes.afterStart = () => {
			// App.log('setup:admin:modules:loaded', 'Zegal', 3);
			App.actions.setupStores().then(() => {
				App.stores.markets.fetch();

				let routes = [];
				routes = [...App.Auth.routes, ...App.UnAuthRoutes];

				routes.push({
					path: '/home',
					options: {exact: true},
					component: App.loadFile({loader: () => import('./modules/home/components/home')}),
					public: true
				});

				routes.push({
					path: '/404',
					options: {exact: true},
					component: App.loadFile({loader: () => import('@zegal/components/src/components/src/404')})
				});

				this.setState({
					authRoutes: routes,
					loading: false
				});
			});
		};

		// console.log('-----------STARTING APP');
	}

	render() {
		const defaultTheme = get(App, 'stores.general.theme.themeDefault');
		if (this.state.loading || !defaultTheme) {
			return (
				<div className='spinner-wrapper'>
					<div className='spinner' />
				</div>
			);
		}
		const ThemeProvider = WrapThemeProvider(defaultTheme);

		App.actions.setupModals(ThemeProvider);

		return (
			<Provider {...App.stores}>
				<Router {...App.stores}>
					<div id='app'>
						<ThemeProvider>
							{/* WHEN we ARE LOGGED IN */}
							<MenuWrapper>
								<AuthWrapper>
									{/*<Switch>*/}
									{this.state.authRoutes &&
										this.state.authRoutes.map((route, i) => (
											<App.RouteBuilder key={'auth-route-' + i} {...route} />
										))}

									{/* always load all routes, */}
									{/* if unauthed, it will redirect to login */}
									{/* then back to correct page after login  */}
									{map(App.modules, (module) => {
										if (isObject(module) && has(module, 'getRoutes')) {
											return map(module.getRoutes, (route, i) => {
												return (
													<App.RouteBuilder
														key={module.channelName + 'route-' + i}
														{...route}
													/>
												);
											});
										}

										if (isObject(module) && has(module, 'routeList') && module.routeList) {
											return map(module.routeList, (route, i) => {
												return (
													<App.RouteBuilder
														key={module.channelName + 'route-' + i}
														{...route}
													/>
												);
											});
										}
									})}
									<EmailConfirmedModal app={App} />
								</AuthWrapper>
							</MenuWrapper>
						</ThemeProvider>
					</div>
				</Router>
			</Provider>
		);
	}
}

function renderApp() {
	ReactDOM.render(React.createElement(AppWrapper, {stores: App.Stores}), document.getElementById('glb-content'));
}

const setupStores = () => {
	return Promise.all([import('./common/stores/general'), import('./common/stores/menu')]).then((results) => {
		const [General, Menu] = results;

		App.stores.general = General.default.create();
		App.stores.menu = Menu.default.create();
	});
};

App.start({config}, {renderApp}, setupStores);
