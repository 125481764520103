export default (theme) => ({
	popper: {
		opacity: 1,

		'& *:empty': {
			display: 'none'
		},

		'& mark': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main
		}
	},

	tooltip: {
		maxWidth: 240,
		padding: 6,
		borderRadius: 3,
		backgroundColor: theme.palette.primary.dark,
		boxShadow: '0 4px 8px rgba(0, 0, 0, .2)',
		fontSize: theme.font.small,
		color: '#fff',
		wordBreak: 'break-word',
		fontWeight: 400,

		'& b': {
			fontWeight: 800
		},

		'& .tooltip-arrow': {
			display: 'block !important',
			color: theme.palette.primary.dark,
		}
	},

	// color
	white: {
		backgroundColor: '#fff',
		color: theme.palette.text.primary,

		'& .tooltip-arrow': {
			color: '#fff',
		}
	},

	// padding
	padding: {
		'&lg': {
			padding: '12px 20px',
			lineHeight: 1.6
		}
	}
});
