import {isString} from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import {
	// Link,
	BrowserRouter as Router
} from 'react-router-dom';

import loadableVisibility from 'react-loadable-visibility/loadable-components';

// import Loading from '@zegal/components/src/components/src/progress/circular'

// displaying nothing
const Loading = () => '';

export const loadFile = (args) => {
	return loadableVisibility(args.loader, {
		fallback: <Loading />
	});
};

export default (App) => {
	App.loadFile = loadFile;
};

export const addLoadingActions = (App) => {
	App.actions.loading = (showGlobal) => {
		App.actions.loadPage(Loading, {}, showGlobal ? '#glb-loading' : undefined);
	};

	App.actions.loadComponent = (Component, options = {}, location) => {
		// give modals the store too
		const FinalForm = options.provide ? App.actions.wrapPage(Component, options) : Component;

		return ReactDOM.render(
			React.createElement(FinalForm, options),
			location || document.getElementById('glb-modal')
		);
	};

	App.actions.loadPage = (Component, options = {}, location) => {
		// console.log('App.pageRegion', App.pageRegion);
		// console.log('Load Component:', Component);
		// console.log('options', options);
		// console.log('Rendering to location:', location);

		if (!location) {
			// console.log('App.pageRegion', App.pageRegion);
			location = '#glb-content';
		}

		if (isString(location)) {
			const locationString = location[0] === '#' ? location.slice(1) : location;
			location = document.getElementById(locationString);
			// location = ReactDOM.findDOMNode(location)
			if (!location) {
				App.log('app_error', {
					message: 'Cannot find location to render component: ' + locationString
				});
			}
		}

		// always be react for now
		App.actions.unmountReactComponent(location);

		const FinalForm = options.provide ? App.actions.wrapPage(Component, options) : Component;

		const WithRouter = () => (
			<Router store={App.stores.general}>
				<FinalForm {...options} />
			</Router>
		);

		return ReactDOM.render(React.createElement(WithRouter, options), location);
	};
};
