import React from 'react';

// nodejs library for concatinating classes
import classNames from 'classnames';
import PropTypes from 'prop-types';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';

import Wrapper from './wrappers/wrapper';

import dividerStyles from './assets/styles/divider';

/*
 * Usage example
 *
 * <Button margin='none | sm | lg' />
 *
 */

function RegularDivider(props) {
	const {classes, margin, dark, children, ...rest} = props;

	const dividerClasses = classNames({
		[classes.divider]: true,
		[classes.dark]: dark,
		[classes.marginRatio + margin]: margin
	});

	const dividerProps = {
		className: dividerClasses
	};

	return (
		<Wrapper display='flex' alignItems='center' {...rest}>
			<Wrapper flex='one'>
				<Divider {...dividerProps} />
			</Wrapper>

			{children && (
				<React.Fragment>
					<div>
						&nbsp;&nbsp;
						{children}
						&nbsp;&nbsp;
					</div>

					<Wrapper flex='one'>
						<Divider {...dividerProps} />
					</Wrapper>
				</React.Fragment>
			)}
		</Wrapper>
	);
}

RegularDivider.propTypes = {
	classes: PropTypes.object.isRequired,
	margin: PropTypes.oneOf(['none', 'xs', 'sm', 'lg'])
};

export default withStyles(dividerStyles)(RegularDivider);
