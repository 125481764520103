export default (theme) => ({
	divider: {
		marginTop: '1.6rem',
		marginBottom: '1.3rem'
	},

	dark: {
		backgroundColor: 'rgba(0, 0, 0, .12)'
	},

	marginRatio: {
		'&none': {
			margin: 0
		},

		'&xs': {
			marginTop: 4,
			marginBottom: 4
		},

		'&sm': {
			marginTop: 12,
			marginBottom: 12
		},

		'&lg': {
			marginTop: 'calc(' + theme.ratio.space.topBottom + ' * 2)',
			marginBottom: 'calc(' + theme.ratio.space.topBottom + ' * 2)'
		}
	}
});
