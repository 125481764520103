import React from 'react';
import {BrowserRouter} from 'react-router-dom';

export default (App) => {
	App.actions.setupModals = (WrapThemeProvider) => {
		App.actions.delete = (props) => {
			import('@zegal/components/src/components/src/modals/delete').then((DeleteModal) => {
				const {handleDelete, ...rest} = props;
				let deleteTitle = props.title;

				const handleDeleteWithNotice = () => {
					handleDelete();

					!props.messageHide &&
						App.actions.message({
							message: () => {
								return (
									<React.Fragment>
										Deleted <span className='color__primary'>{deleteTitle}</span>
									</React.Fragment>
								);
							},
							good: true
						});
				};

				App.actions.loadComponent(
					() => (
						<BrowserRouter>
							<WrapThemeProvider>
								<DeleteModal.default {...rest} handleDelete={handleDeleteWithNotice} />
							</WrapThemeProvider>
						</BrowserRouter>
					),
					{provide: ['general']}
				);
			});
		};

		App.actions.deleteFullScreenModal = (props) => {
			import('@zegal/components/src/components/src/modals/delete-full-screen').then((DeleteModal) => {
				const {handleDelete, ...rest} = props;
				let deleteTitle = props.title;

				const handleDeleteWithNotice = () => {
					handleDelete();

					!props.messageHide &&
						App.actions.message({
							message: () => {
								return (
									<React.Fragment>
										Deleted <span className='color__primary'>{deleteTitle}</span>
									</React.Fragment>
								);
							},
							good: true
						});
				};

				App.actions.loadComponent(
					() => (
						<BrowserRouter>
							<WrapThemeProvider>
								<DeleteModal.default {...rest} handleDelete={handleDeleteWithNotice} />
							</WrapThemeProvider>
						</BrowserRouter>
					),
					{provide: ['general']}
				);
			});
		};

		App.actions.showModal = ({Component, ...props}, options) => {
			import('@zegal/components/src/components/src/modals/modal').then((Modal) => {
				App.actions.loadComponent(
					() => (
						<BrowserRouter>
							<WrapThemeProvider>
								<Modal.default {...props}>
									<Component {...props} />
								</Modal.default>
							</WrapThemeProvider>
						</BrowserRouter>
					),
					options
				);
			});
		};

		App.actions.promptModal = (props) => {
			import('@zegal/components/src/components/src/modals/prompt').then(({PromptModal}) => {
				App.actions.loadComponent(
					() => (
						<BrowserRouter>
							<WrapThemeProvider>
								<PromptModal {...props} />
							</WrapThemeProvider>
						</BrowserRouter>
					),
					{provide: ['general']}
				);
			});
		};

		App.actions.startTour = (props) => {
			import('@zegal/components/src/components/src/modals/tour').then((Tour) => {
				App.actions.loadComponent(
					() => (
						<BrowserRouter>
							<WrapThemeProvider>
								<Tour.default {...props} />
							</WrapThemeProvider>
						</BrowserRouter>
					),
					{provide: ['general']}
				);
			});
		};

		App.actions.message = (props) => {
			const renderOn = document.getElementById('glb-message');

			import('@zegal/components/src/components/src/message').then((Message) => {
				App.actions.loadComponent(
					() => (
						<BrowserRouter>
							<WrapThemeProvider>
								<Message.default {...props} />
							</WrapThemeProvider>
						</BrowserRouter>
					),
					{provide: ['general']},
					renderOn || document.getElementById('glb-modal')
				);
			});
		};

		App.actions.confirmModal = (props) => {
			import('@zegal/components/src/components/src/modals/simpleConfirm').then(({SimpleConfirmModal}) => {
				App.actions.loadComponent(
					() => (
						<BrowserRouter>
							<WrapThemeProvider>
								<SimpleConfirmModal {...props} />
							</WrapThemeProvider>
						</BrowserRouter>
					),
					{provide: ['general']}
				);
			});
		};

		App.actions.loadTemporaryComponent = ({Component, props, options = {provide: ['general']}, location}) => {
			return App.actions.loadComponent(
				() => (
					<BrowserRouter>
						<WrapThemeProvider>
							<Component {...props} />
						</WrapThemeProvider>
					</BrowserRouter>
				),
				options,
				location
			);
		};
	};
};
