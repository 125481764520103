import {values} from 'mobx';
import {getParent, getRoot, applySnapshot, getEnv} from 'mobx-state-tree';

export const setKey = (self) => (key, value) => {
	self[key] = value;
	return self[key];
};

export const serverCall = (self, App?) => (url, data, method = 'POST', options = {}) => {
	let importingServer;

	App = App || getEnv(self).app;

	if (App.getConfig('ajaxMode') === 'Axios') {
		importingServer = import('./serverCallAxios');
		// server = import(`./serverCall${App.getConfig('ajaxMode')}`)
	} else {
		console.error('Trying to use unsupported version of ajax mode.');
	}

	return importingServer.then((imported) => {
		// console.log('method', method);
		// console.log('imported', imported);
		const server = imported.default(App);
		if (!server[method]) {
			console.error('MISSING AJAX METHOD:', method);
			return Promise.reject();
		}

		return server[method](url, data, options);
	});
};

export default (self) => {
	return {
		setKey: setKey(self),
		serverCall: serverCall(self),
		_POST(endpoint, data) {
			return self.serverCall(`${self.url}/${self._id}/${endpoint}`, data, 'POST');
		},

		// if you want to override this in your model, you can
		// NOTE: this is called after a FETCH on the parent collection, when adding this item.
		update(data) {
			self._update(data);
		},

		_update(data) {
			applySnapshot(self, data);
		}
	};
};

export const generalViews = (self) => {
	return {
		// this is actually a collection view function?
		getValues(source) {
			if (source) {
				return values(source);
			}

			if (self.models) {
				return values(self.models);
			}

			console.error('Cannot find what you want!');
			return [];
		},

		get root() {
			return getRoot(self);
		},

		get parent() {
			return getParent(self);
		},

		log(...args) {
			// eslint-disable-next-line no-restricted-syntax
			self.debug && console.log(...args);
		}
	};
};
