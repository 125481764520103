import {find} from 'lodash';
import {
	decorate,
	action,
	// computed,
	observable
} from 'mobx';
// import Backbone from 'backbone'

import App from '@zegal/payments/src/app';
import Base from '@zegal/components/src/base/src/common/stores/base';

class Store extends Base {
	/**
	 * MISC Stuff
	 *
	 *
	 *
	 */
	setLayoutHeader({title, back, backUrl, actions}) {
		this.LayoutHeader = {
			title,
			back,
			backUrl,
			actions
		};
	}

	Layout = find(App.modules, {moduleName: 'Layout'});
	LayoutHeader = {
		back: false,
		backUrl: null,
		title: 'Payments',
		actions: []
	};
}

decorate(Store, {
	setLayoutHeader: action,
	Layout: observable,
	LayoutHeader: observable
});

export default Store;
