import {fade} from '@material-ui/core/styles/colorManipulator';
import {
	focusShadow,
	focusOneShadow,
	primaryBackground,
	secondaryBackground,
	dangerBackground,
	warningBackground,
	disabledBackground,
	marginRatio,
	paddingRatio,
	ratio
} from '@zegal/components/src/components/src/assets/global.style';
import {createStyles} from '@material-ui/core/styles';

const paperStyle = (theme) => {
	if (theme.color) {
		return createStyles(({
			paper: {
				width: '100%',
				marginBottom: ratio.space.topBottom,
				padding: ratio.space.topBottom + ' ' + ratio.space.rightLeft,
				backgroundColor: theme.palette.background ? theme.palette.background.paper : '#fff',
				boxSizing: 'border-box',
				borderRadius: 3,
				boxShadow: 'none',
				overflow: 'visible',
				'-webkit-overflow-scrolling': 'touch',
				border: '1px solid',
				borderColor: theme.palette.divider,
				position: 'relative',

				[theme.breakpoints.up('md')]: {
					marginBottom: 'calc(' + ratio.space.topBottom + ' * 2)'
				},

				// conditions
				'&:empty': {
					display: 'none'
				}
			},

			// color (refeence property)
			primary: {
				...primaryBackground(theme)
			},

			secondary: {
				...secondaryBackground(theme)
			},

			bodyColor: {
				backgroundColor: theme.palette.background.default
			},

			disabled: {
				...disabledBackground(theme)
			},

			danger: {
				...dangerBackground(theme)
			},

			warning: {
				...warningBackground(theme)
			},

			white: {
				'&, &:focus, &:hover': {
					backgroundColor: '#fff !important'
				}
			},

			'inherit-nav': {
				'&, &:focus, &:hover': {
					backgroundColor: theme.palette.primary.dark,
					color: '#fff'
				}
			},

			transparent: {
				'&, &:focus, &:hover': {
					backgroundColor: 'transparent !important'
				}
			},

			// padding
			paddingRatio: {
				...paddingRatio(theme)
			},

			// margin
			marginRatio: {
				...marginRatio(theme)
			},

			paperBorder: {
				'&, &:focus, &:hover, &:visited': {
					backgroundColor: theme.palette.background ? theme.palette.background.paper : '#fff',
					borderWidth: 1,
					borderColor: 'rgba(223, 224, 228, 0.5) rgba(223, 224, 228, 0.7) rgba(223, 224, 228, 0.8)',
					color: 'inherit',
					overflow: 'hidden'
				},

				'&dashed': {
					borderRadius: 0,
					borderWidth: 2,
					borderStyle: 'dashed'
				},

				'&$primary': {
					borderColor: theme.palette.primary.dark + '!important'
				},

				'&$secondary': {
					borderColor: theme.palette.secondary.main + '!important'
				},

				'&$danger': {
					borderColor: theme.palette.error.main + '!important'
				},

				'&$warning': {
					borderColor: theme.color.default.warning + '!important'
				}
			},

			paperOpacity: {
				'&, &:focus, &:hover, &:visited': {
					backgroundColor: theme.palette.background ? theme.palette.background.paper : '#fff',
					color: 'inherit',
					borderColor: 'transparent',
					boxShadow: 'none'
				},

				'&$primary': {
					backgroundColor: theme.palette.primary.light,
					color: 'inherit'
				},

				'&$secondary': {
					backgroundColor: theme.palette.secondary.light,
					color: 'inherit'
				},

				'&$danger': {
					backgroundColor: fade(theme.palette.error.main, .2),
					color: 'inherit'
				},

				'&$warning': {
					backgroundColor: fade(theme.color.default.warning, .2),
					color: 'inherit'
				}
			},

			paperFocus: {
				border: 0,
				boxShadow: focusShadow,

				'&$primary': {
					backgroundColor: theme.palette.background ? theme.palette.background.paper : '#fff',
					borderTop: '5px solid' + theme.palette.primary.main,
					color: theme.palette.text.primary
				},

				'&$secondary': {
					backgroundColor: theme.palette.background ? theme.palette.background.paper : '#fff',
					borderTop: '5px solid' + theme.palette.secondary.main,
					color: theme.palette.text.primary
				},

				'&$danger': {
					backgroundColor: theme.palette.background ? theme.palette.background.paper : '#fff',
					borderTop: '5px solid' + theme.palette.error.main
				},

				'&$warning': {
					backgroundColor: theme.palette.background ? theme.palette.background.paper : '#fff',
					borderTop: '5px solid' + theme.color.default.warning
				},

				'&one': {
					border: 0,
					borderRadius: '3px',
					boxShadow: focusOneShadow
				},

				'&two': {
					border: 0,
					borderRadius: '3px',
					boxShadow: theme.shadows[2]
				},

				'&three': {
					border: 0,
					borderRadius: '3px',
					boxShadow: theme.shadows[3]
				}
			},

			paperSlat: {
				'& > *': {
					border: 0,
					borderBottom: '1px solid ' + theme.palette.divider,
					borderRadius: 0,
					boxShadow: 'none'
				},

				'& > *:last-child, & ul div:last-child > li': {
					borderBottom: 0
				}
			},

			paperDisplay: {
				'&fullHeight': {
					height: '100%'
				},

				'&table-md-up': {
					[theme.breakpoints.up('md')]: {
						display: 'table',

						'&$paperSlat': {
							'& > tr:not(:last-child)': {
								'& td': {
									borderBottom: '1px solid ' + theme.palette.divider
								}
							}
						},

						'& td.table-cell--info': {
							width: '40%'
						},

						'& td.table-cell--info .info-wrapper': {
							maxWidth: 250,
							paddingRight: 16
						}
					},
				},
			},

			paperPanel: {
				'&, &:focus, &:hover, &:visited': {
					backgroundColor: theme.color.default.background_surface,
					border: 0,
					borderRadius: 0,
					boxShadow: 'none',
				},

				'&$primary': {
					backgroundColor: theme.palette.primary.light
				},

				'&$secondary': {
					backgroundColor: theme.palette.secondary.light
				},

				'&$danger': {
					backgroundColor: fade(theme.color.default.error, .3)
				},

				'&$warning': {
					backgroundColor: fade(theme.color.default.warning, .3)
				},

				'&$paperFocus': {
					borderLeft: '3px solid ' + theme.palette.secondary.main
				}
			},

			paperCover: {
				display: 'block',
				height: '100%'
			},

			paperReset: {
				// reset styles
				border: 'none',
				boxShadow: 'none',
				marginBottom: 0,

				'&radius': {
					border: 'none',
					borderRadius: '0'
				}
			},

			// specificly designed for Drag and drop paper
			paperWrappable: {
				padding: '1.6rem 1.3rem 1.6rem 3.9rem',

				// If paper have wrap (Drag) icon
				'&.is__wrap': {
					paddingLeft: '0'
				}
			},

			// close button
			buttonClose: {
				position: 'absolute',
				right: 0,
				top: 0,
				zIndex: 11,

				'& *': {
					fontWeight: 600,
					opacity: 0.8
				},

				'&:hover': {
					'& *': {
						opacity: 1,
						fontWeight: 600
					}
				}
			}
		}));
	}

	return {};
};

export default paperStyle;
