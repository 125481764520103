export default {
	/**
	 *
	 * Development CONFIG (Dev)
	 *
	 */

	APIRoot: 'https://api.dev-a.drabayashi.net', // Dev
	ZAPIRoot: 'https://zapi.dev-a.drabayashi.net', // Dev
	APIPort: '443',
	SocketPort: '443',
	registrationRoot: 'https://register.dev.drabayashi.net',
	AppRoot: 'https://dte-app.dev.drabayashi.net',
	ScribeRoot: 'https://api.dev-a.drabayashi.net',

	// 0 - Prod (Only log errors)
	// 1 - Log to Kafka
	// 2 - Log in console
	// 3 - Just plain crazy
	logDisplayLevel: 2,
	logSaveLevel: 1,
	logAppErrors: false,
	logLimit: 1,

	domain: 'payments',
	customTitle: 'Loading...',
	features: {
		auth: {
			disableStatusCheck: true,
			disableDash: true,
			disableAPIKey: true,
			disableShowDL2Login: true
		},

		useV2Data: false,
		registration: false,
		sockets: false,
		emailConfirm: true,
		cookies: {
			enabled: true,
			secure: false,
			domain: 'drabayashi.net'
		},

		integrations: {
			mixPanel: {
				enabled: true,
				passive: true,
				key: '13ed044f41a947f47908b2b309b42d2b'
			},
			intercom: {
				enabled: false,
				default: 'qftv3qdb', // for not logged in intercom
				passive: true // <- an integration token is not required to load this
			},
			ga: {
				enabled: true,
				key: 'UA-77407195-3'
			}
		},
		ignoreConfirmEmail: true,
		ajaxMode: 'Axios',
		maxAjaxRetries: 2,
		setViewTemplateUrlByJURI: true
	},

	debug: true, // add app to window etc

	throwErrors: true,
	whiteLabel: false,

	Payments: {
		stripe: {
			HKG: 'pk_test_SIFUJZEk4803VP6DbuhoWc3Q',
			DEFAULT: 'pk_test_SIFUJZEk4803VP6DbuhoWc3Q' // <- required!!

			// old
			// SGP: 'pk_test_HvBehlPNbr8yjWSoieZWHMZO'
		}
	},
	passwordValidation: 'required|min:8|max:100',
	freePlanName: 'NO-PAID-PRIMARY-PLAN'
};
