import {includes, filter, get} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

import App from '@zegal/payments/src/app';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import {withStyles} from '@material-ui/core/styles';

import MenuOption from '@zegal/components/src/components/src/options/menu';
import Wrapper from '@zegal/components/src/components/src/wrappers/wrapper';
import Button from '@zegal/components/src/components/src/buttons/button';
// import {LogoWithText, Icon} from '@zegal/components/src/components/src/icons';
import {Icon} from '@zegal/components/src/components/src/icons';
import WhiteLabelLogo from '@zegal/components/src/components/themeSettings/logo';
import styles from './topBar.styles';

class TopBar extends React.Component {
	state = {
		menuOpen: false
	};

	menus = [];

	handleLogoClick = () => {
		if (includes(get(App.stores.user, 'roles').toJSON(), 'USER')) {
			this.props.history.push(App.getConfig('homepath'));
		}
	};

	handleHamburgerClick = () => {
		this.setState({
			menuOpen: !this.state.menuOpen
		});
	};

	goToApp() {
		const win = window.open(App.getConfig('AppRoot'), '_blank');
		win.focus();
	}

	render() {
		const {classes, history, menu} = this.props;
		const allowedMenus = filter(this.menus, (item) => includes(get(App.stores.user, 'roles').toJSON(), item.role));

		if (!menu.appMenuBar) {
			return null;
		}

		return (
			<AppBar
				color='inherit'
				position='fixed'
				classes={{
					root: classes.appBar
				}}
			>
				<Toolbar classes={{root: classes.toolBar}} variant='regular'>
					<Wrapper alignItems='center' flex='one' justify='space-between'>
						<Wrapper
							data-identifier='topbar__logowithtext'
							onClick={this.handleLogoClick}
							className={classes.logo}
						>
							{/* <LogoWithText /> */}
							<WhiteLabelLogo />
						</Wrapper>

						<Hidden mdUp>
							<MenuOption
								options={[
									{
										text: 'Go to App',
										Icon: 'keyboard_arrow_left',
										handleClicked: this.goToApp
									},
									{
										text: 'Sign out',
										Icon: 'exit_to_app',
										handleClicked: () => {
											window.location.href = '/logout';
										}
									}
								]}
							/>
						</Hidden>
					</Wrapper>

					<Wrapper direction='row-md-up' className={classes.toolBarMenu}>
						<Button data-identifier='topbar__redirect' onClick={this.goToApp} simple disableRipple withIcon>
							{Icon('keyboard_arrow_left')} Go to App
						</Button>

						<Button to='/logout' border>
							Sign out
						</Button>
					</Wrapper>

					{allowedMenus.map((menu, index) => {
						const Component = menu.component;

						return <Component key={'topbar' + index} history={history} />;
					})}
				</Toolbar>
			</AppBar>
		);
	}
}

TopBar.propTypes = {
	classes: PropTypes.object.isRequired,
	open: PropTypes.bool
};

export default withStyles(styles)(inject('general', 'menu')(observer(TopBar)));
