import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';

import iconWrapperStyle from '../assets/styles/wrapper/icon';
import Wrapper from './wrapper';

export interface IconWrapperProps {
	classes?: any;
	color?: string | boolean;
	size?: string | boolean;
	outlined?: boolean;
	margin?: string | boolean;
	isHover?: boolean;
	component?: string;
	display?: string;
	under?: string;
	justify?: string;
	className?: any;
	onClick?: () => void;
	id?: string;
	alignItems?: string;
	style?: any;
}

const IconWrapper: React.SFC<IconWrapperProps> = ({
	children,
	classes,
	color,
	size,
	outlined,
	margin,
	isHover,
	className,
	onClick,
	justify,
	...rest
}) => {
	const iconClasses = classNames({
		'material-icons': !outlined,
		[classes.icon]: true,
		[classes.isHover]: isHover || onClick,
		[classes.iconColor + color]: color || 'inherit',
		[classes.iconSize + size]: size,
		'material-icons-outlined': outlined,
		[classes.marginRatio + margin]: margin,
		[className]: true
	});

	const wrapperProps = {
		display: 'flex',
		className: iconClasses,
		onClick: onClick,
		...rest
	};

	return (
		<Wrapper justify={justify} {...wrapperProps}>
			{children}
		</Wrapper>
	);
};

IconWrapper.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf([
		'inherit',
		'primary',
		'primaryLight',
		'secondary',
		'textPrimary',
		'white',
		'black',
		'success',
		'danger',
		'warning',
		false
	]),
	size: PropTypes.oneOf([
		'xs',
		'sm',
		'md',
		'lg',
		'xl',
		'xxl',
		'hero', // larger than anyone as a hero
		'auto',
		false
	]),
	outlined: PropTypes.bool,
	margin: PropTypes.oneOf(['sm', 'lg', 'xl', 'only-left', 'only-right', 'none', false]),
	isHover: PropTypes.bool
};

export default withStyles(iconWrapperStyle)(IconWrapper);
