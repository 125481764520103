import '@zegal/components/src/base/src/common/vendor/customPolyfill';
// THIS IS THE FILE THAT IS LOADED BY LIKE EVERYTHING
// WHEN YOU DO AN:
// import App from '@zegal/payments/src/app'
import App from '@zegal/components/src/base/src/core';
import roleMap from './common/roleMap';

import LayoutStore from './common/stores/layout';

// test environment doesn't have actions object
if (!App.actions) {
	App.actions = {};
}

App.actions.makeSureUserCanLoadApp = () => {
	// user subscription validation check = 0
};

App.actions.setupStores = () => {
	return import('./common/stores/root').then((storeRoot) => {
		App.stores.layout = (LayoutStore as any).create();

		App.stores.store = storeRoot.default().create({});

		// force redirect to home
		if (!App.stores.general.isAuthenticated) {
			const url = window.location.pathname;

			if (url === '/') {
				if (!url.match(/register\/[a-z]+/g)) {
					window.location.href = '/home';
				}
			}
		}
	});
};

App.roleMap = roleMap;
export default App;
