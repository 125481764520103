import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';

import Popper from '@zegal/components/src/components/src/popper/uncontrolled';
import Wrapper from '@zegal/components/src/components/src/wrappers/wrapper';
import IconButton from '@zegal/components/src/components/src/buttons/icon';
import Divider from '@zegal/components/src/components/src/divider';
import {TextWithIcon, Normal} from '@zegal/components/src/components/src/typography';
import Tooltip from '@zegal/components/src/components/src/tooltips/tooltip';

export interface MenuItemType {
	Icon?: string;
	divider?: boolean;
	text?: string;
	key?: string;
	handleClicked?: (email: string) => void;
	disabled?: boolean;
	tooltip?: string;
}

interface Props {
	placement?: string;
	customTarget?: any;
	options: MenuItemType[];
	style?: any;
	targetIcon?: string;
}

class Menus extends React.Component<Props> {
	handleMenuClick = (handleClicked, handleClose) => (e) => {
		e.stopPropagation();
		handleClose(e);
		handleClicked();
	};

	render() {
		const {placement, customTarget, options, style, targetIcon = 'more_vert'} = this.props;

		return (
			<Wrapper>
				<Popper
					placement={placement}
					target={(handleClick, open) =>
						customTarget ? (
							customTarget(handleClick, open)
						) : (
							<IconButton
								data-zegal-component='menu-action-open'
								onClick={handleClick}
								icon={targetIcon}
								simple
								color='primary'
							/>
						)
					}
					content={(handleClose) => {
						if (options.length > 0) {
							return (
								<List>
									{options.map(({text, Icon, divider, handleClicked, key, disabled, tooltip}) => {
										if (divider) {
											return (
												<Divider inner='flat' dark margin='xs' key={'divider' + (key || '')} />
											);
										}

										return (
											<React.Fragment key={text + (key || '')}>
												<Tooltip content={tooltip}>
													<ListItem
														data-identifier={`menu-item-${text}`}
														onClick={this.handleMenuClick(handleClicked, handleClose)}
														style={style}
														disableRipple
														disabled={disabled}
														button
													>
														{!Icon ? (
															<Normal variant='subtitle2' color='primary'>
																{text}
															</Normal>
														) : (
															<TextWithIcon
																icon={Icon}
																color={disabled ? 'textSecondary' : 'primary'}
																tooltip
															>
																<Normal
																	variant='subtitle2'
																	color={disabled ? 'textSecondary' : 'textPrimary'}
																>
																	{text}
																</Normal>
															</TextWithIcon>
														)}
													</ListItem>
												</Tooltip>
											</React.Fragment>
										);
									})}
								</List>
							);
						}

						return null;
					}}
				/>
			</Wrapper>
		);
	}
}

export default Menus;
