import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import Modal from './modals/modal';
import Wrapper from './wrappers/wrapper';
import IconWrapper from './wrappers/icon';
import Button from './buttons/loading';
// import {IconSvg} from './icons'

/*
	Frequently used Icon component (Explicitly import it from '@zegal/components/src/components/src/icons)
		MailExpired,
		MailConfirmed,
		MailColor

	Usage Example:

	<AlertModal
		title='Please confirm your email'
		Icon={MailConfirmed}
		description1='Note that link expires'
		description2='If you didn't get the confirmation email or the link'
		button={{
			text: LOGIN,
			onClick: this.handleClick
		}}
		onClose={this.handleClose}
	/>
*/

export const ModalBody = ({
	title,
	Icon,
	email,
	description2,
	description1,
	// timeInterval,
	button,
	ChildComponent,
	childComponentProps,
	children
}) => {
	// console.log({
	// 	title,
	// 	Icon,
	// 	email,
	// 	description2,
	// 	description1,
	// 	timeInterval,
	// 	button,
	// 	ChildComponent,
	// 	childComponentProps,
	// 	children
	// })

	return (
		<Wrapper under='header'>
			<Typography variant='h4' align='center' paragraph>
				{title}
			</Typography>

			{Icon && (
				<IconWrapper size='xl' justify='center'>
					<Icon />
				</IconWrapper>
			)}

			<Wrapper under='header'>
				{email && (
					<Typography variant='subtitle2' gutterBottom align='center'>
						{email}
					</Typography>
				)}

				<Typography variant='body1' align='center'>
					{description1}
				</Typography>

				<Typography component='div' variant='body1' align='center' paragraph>
					{description2}
				</Typography>

				{button && (
					<Wrapper inner='flat'>
						<Button id={button.id} color='primary' onClick={button.onClick} fullWidth>
							{button.text}
						</Button>
					</Wrapper>
				)}

				{ChildComponent && <ChildComponent {...childComponentProps} />}

				{children && children}
			</Wrapper>
		</Wrapper>
	);
};

const AlertModal = (props) => (
	<Modal showCloseButton>
		<span>
			<ModalBody {...props} />
		</span>
	</Modal>
);

AlertModal.propTypes = {
	title: PropTypes.string,
	Icon: PropTypes.node,
	description1: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
	description2: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
	button: PropTypes.object,
	onClose: PropTypes.object,
	children: PropTypes.func,
	ChildComponent: PropTypes.func,
	childComponentProps: PropTypes.object
};

export default AlertModal;
