import React from 'react';
import propTypes from 'prop-types';

import Popper from '@material-ui/core/Popper';

import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {withStyles} from '@material-ui/core/styles';

import Paper from '../papers/paper';
import Wrapper from '../wrappers/wrapper';
import popperStyle from '../assets/styles/popper';

const RegularPopper = (props) => {
	const targetRef = React.useRef();
	const {classes, className, size, placement, target, content, open, onClickAway, style} = props;

	return (
		<Wrapper className={className}>
			{target && <div ref={targetRef}>{target()}</div>}

			{targetRef?.current && (
				<Popper
					anchorEl={targetRef?.current}
					placement={placement}
					open={open}
					className={!open ? classes.popperOnClose : ''}
					popperOptions={{
						positionFixed: true
					}}
					style={{zIndex: 1500}}
				>
					<ClickAwayListener onClickAway={onClickAway}>
						<div className='popper-menu'>
							<Fade in={open} id='menu-list'>
								<Paper
									padding='none'
									margin='none'
									focus='one'
									className={`${classes.popper} ${size}`}
									style={style}
								>
									{content()}
								</Paper>
							</Fade>
						</div>
					</ClickAwayListener>
				</Popper>
			)}
		</Wrapper>
	);
};

RegularPopper.defaultProps = {
	placement: 'bottom',
	content: () => <Paper color='danger'>Empty popup.</Paper>,
	onClickAway: () => {
		return false;
	}
};

RegularPopper.propTypes = {
	open: propTypes.bool,
	placement: propTypes.string,
	target: propTypes.func.isRequired,
	content: propTypes.func
};

export default withStyles(popperStyle)(RegularPopper);
